<template>
  <div>
    <div v-if="list.length > 0">
      <div
        class="mySocial_list"
        v-for="(item, index) in list"
        :key="index"
        @click="toSocialInfo(item)"
      >
        <div class="mySocial_flex mySocial_top">
          <div>{{ item.person_name }}的{{ item.month }}月缴纳记录</div>
          <div>
            <!-- <span class="mySocial_status" v-if="{{item.status==0}}">缴纳成功</span>
        <span v-else class="mySocial_statusb">缴纳失败</span> -->
            <!-- <span class="mySocial_status">{{item.status}}</span> -->
            <span class="mySocial_status">{{ state[item.status] }}</span>
          </div>
        </div>
        <div class="mySocial_content">
          <van-row class="mySocial_line">
            <van-col span="10">参保人：{{ item.person_name }}</van-col>
            <van-col span="14">身份证号：{{ item.person_card_code }}</van-col>
          </van-row>
          <van-row class="mySocial_line">
            <van-col span="10">参保城市：{{ item.city_id }}</van-col>
            <van-col span="14">参保月：{{ item.month }}月</van-col>
          </van-row>
          <div>参保类型：{{ socialtypeList[item.socialtype] }}</div>
        </div>
      </div>
      <van-row>
        <van-col span="18" offset="3">
          <div class="margin_div_bottom">
            <van-loading size="48rpx" v-if="!isBottom">加载中...</van-loading>
          </div>
          <div v-if="isBottom" class="margin_div_bottom">暂无更多数据了</div>
        </van-col>
      </van-row>
    </div>
    <div v-else class="noStatus">暂无相关数据</div>
  </div>
</template>
<script>
// import { formatMinute } from "../utils/tool";
export default {
  data() {
    return {
      list: [],
      pid: "",
      id: "",
      state: [], //社保状态
      cityList: [], //城市列表
      socialtypeList: [], //筛选列表
      pageNum: 1,
      isBottom: false,
    };
  },
  mounted() {
    let pid = localStorage.getItem("pid");
    let id = localStorage.getItem("id");
    this.pid = pid;
    // 测试
    // this.pid=53738;
    this.id = id;
  },
  methods: {
    //社保详情
    toSocialInfo(val) {
      this.$router.push({ name: "socialInfo", params: { id: val.id } });
    },
  },
};
</script>
<style scoped>
.mySocial_list {
  height: 10.25rem;
  border: 0.0625rem solid #ddd;
  width: calc(100% - 1.875rem);
  margin: 0 auto;
  margin-top: 0.625rem;
  border-radius: 0.625rem;
}

.mySocial_flex {
  display: flex;
  justify-content: space-between;
}

.mySocial_top {
  line-height: 3.125rem;
  padding: 0 0.9375rem;
  box-sizing: border-box;
}

.mySocial_status,
.mySocial_statusb {
  background-color: #2694fd;
  height: 1.625rem;
  padding: 0.3125rem 0.625rem;
  border-radius: 0.625rem;
  color: #fff;
  font-size: 0.9375rem;
}

.mySocial_statusb {
  background-color: #e21526;
}

.mySocial_content {
  font-size: 0.875rem;
  padding: 0.625rem 0.3125rem;
  box-sizing: border-box;
  border-top: 0.0625rem solid #ccc;
}

.mySocial_line {
  line-height: 1.5625rem;
}
.noStatus {
  text-align: center;
  margin-top: 1.25rem;
}

.margin_div_bottom {
  text-align: center;
  margin: 1.25rem 0;
}
</style>
<style>
</style>
